import { Alert, Divider, theme } from "antd";
import IndexLink from "components/index-link";
import { useSelector } from "react-redux";
import { ROLE_ENGINEERING } from "shared/roles";
import { hasRole } from "shared/utils";

export default function AccountManagementPage() {
    const {
        token: { colorPrimary },
    } = theme.useToken();

    const { roles } = useSelector((state: any) => state.user);

    return (
        <>
            <h1>Account Management</h1>

            <Alert
                type="warning"
                message="PLEASE NOTE"
                description="The following function can cause irreversible changes to the system. Please ensure you have the correct permissions before proceeding. ALL actions will be tracked for auditing purposes."
            />

            <Divider style={{ borderColor: colorPrimary }}>Collections</Divider>

            <IndexLink
                to="/account-management/collections/create"
                title="Create Ad-Hoc Collection"
            >
                Create a single collection against a property/meter (e.g. for a
                specific debt)
            </IndexLink>

            <IndexLink
                to="/account-management/repeated-collections/create"
                title="Create Repeated Collection"
            >
                Create a repeated collection against a property/meter (e.g. for
                a monthly debt)
            </IndexLink>

            <Divider style={{ borderColor: colorPrimary }}>Tokens</Divider>
            {hasRole(roles, [ROLE_ENGINEERING]) && (
                <>
                    <IndexLink
                        to="/account-management/engineering/generate-tokens"
                        title="Generate Engineering Token(s)"
                    >
                        Quick generation of engineering tokens for a meter (e.g.
                        Clear Tamper during installations)
                    </IndexLink>
                </>
            )}

            <IndexLink
                to="/account-management/engineering/generate-usage-tokens"
                title="Generate Usage Token"
            >
                Quick generation of usage tokens for a meter (e.g. for
                installation)
            </IndexLink>

            <Divider style={{ borderColor: colorPrimary }}>Take On</Divider>

            <IndexLink to="/take-on/add-meters" title="Add Meter(s)">
                A quick add of one or more meters
            </IndexLink>
            <IndexLink to="/take-on/import-profile" title="Profile Import">
                When you have a lot of meters to add, you can import them in
                bulk via an excel file upload.
            </IndexLink>

            <Divider style={{ borderColor: colorPrimary }}>Transfers</Divider>

            <IndexLink to="/transfer/meters" title="Transfer Meter(s)">
                Ability to transfer one or more meter to another provider
                (includes KCT and a summary report on the meter)
            </IndexLink>

            <IndexLink to="/transfer/export-profile" title="Profile Export">
                Generates an excel sheet of all the information required to
                transfer meters to another provider
            </IndexLink>
        </>
    );
}
