import { AutoComplete, AutoCompleteProps } from "antd";
import { useState } from "react";
import { debounce } from "shared/utils";
import AutocompleteItem from "./autocomplete-item";

interface IEntityAutocompleteProps {
    placeholder?: string;
    entityType: string;
    onSelect: (item: { id: string; label: string } | null) => void;
}

export default function EntityAutocomplete({
    placeholder,
    entityType,
    onSelect,
}: IEntityAutocompleteProps) {
    const [opts, setOptions] = useState<AutoCompleteProps["options"]>([]);
    const onSelectCB = (label: string, item: any) =>
        onSelect({ id: item.id, label });
    const onSearch = debounce(async (searchText: string) => {
        if (!searchText) {
            setOptions([]);
            return;
        }

        const response = await fetch(
            `/api/1/auto-complete/${entityType}?q=` + searchText
        );
        const data = await response.json();
        if (data === "Unauthorized") {
            // TODO: alert and then navigate to login
            return;
        }
        if (!data) {
            setOptions([]);
            return;
        }

        setOptions(
            data.map((item: any) => ({
                id: item.id,
                value: item.label,
                label: <AutocompleteItem item={item} />,
            }))
        );
    }, 500);

    return (
        <AutoComplete
            allowClear
            placeholder={placeholder || "Search..."}
            options={opts}
            style={{ width: "100%" }}
            onClear={() => onSelect(null)}
            onSelect={onSelectCB}
            onSearch={onSearch}
        />
    );
}
