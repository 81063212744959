import React from "react";
import ReactDOM from "react-dom/client";
import App from "./pages/authed/App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import store from "./stores/app-store";
import "./index.css";
import HomePage from "./pages/authed/home";
import LoginPage from "./pages/not-authed/login";
import { ConfigProvider } from "antd";
import SettingsPage from "pages/authed/settings";
import NotFoundPage from "pages/shared/not-found";
import ReportsPage from "pages/authed/reports";
import ProfileOverviewPage from "pages/authed/reports/profile-overview";
import AccountMaintenancePage from "pages/authed/account-management";
import CreateCollectionPage from "pages/authed/management/collections/create-collection";
import GenerateEngineeringTokensPage from "pages/authed/management/tokes/generate-engineering-tokens";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                index: true,
                element: <HomePage />,
            },
            {
                path: "/reports",
                children: [
                    {
                        index: true,
                        element: <ReportsPage />,
                    },
                    {
                        path: "profile-overview",
                        element: <ProfileOverviewPage />,
                    },
                ],
            },
            {
                path: "/account-management/",
                children: [
                    {
                        index: true,
                        element: <AccountMaintenancePage />,
                    },
                    {
                        path: "collections",
                        children: [
                            {
                                path: "create",
                                element: <CreateCollectionPage />,
                            },
                        ],
                    },
                    {
                        path: "engineering",
                        children: [
                            {
                                path: "generate-tokens",
                                element: <GenerateEngineeringTokensPage />,
                            },
                        ],
                    },
                ],
            },
            {
                path: "/settings",
                element: <SettingsPage />,
            },
        ],
        ErrorBoundary: NotFoundPage,
    },
    {
        path: "/login",
        element: <LoginPage />,
    },
]);

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: "#347564",
                        colorInfo: "#347564",
                        colorSuccess: "#7fd455",
                        colorWarning: "#e29c0f",
                        colorError: "#e76668",
                        borderRadius: 6,
                    },
                    components: {
                        Layout: {
                            headerBg: "#347564",
                        },
                    },
                }}
            >
                <RouterProvider router={router} />
            </ConfigProvider>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
