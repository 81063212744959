import './App.css';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import { menuItems, getBreadcrumbs, getRoleForPath } from 'shared/menu-info';
import { Input } from 'antd';
import { useSelector } from 'react-redux'
// import { useEffect } from 'react';
import { hasRole } from 'shared/utils';
import useAuth from "shared/use-auth";

const { Search } = Input;
const { Header, Content, Footer } = Layout;

function App() {
    const navigate = useNavigate();
    const location = useLocation();
    const { roles } = useSelector((state: any) => state.user)

    useAuth();

    // useEffect(() => {
    //     if (!authenticated) {
    //         navigate('/login')
    //     }
    // }, [navigate, authenticated]);


    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    const crumbs = getBreadcrumbs(location.pathname);
    const onSearch = (value: string) => console.log(value);

    return (
        <Layout>
            <Header style={{ display: 'flex', alignItems: 'center', padding: '24px', paddingRight: 0 }}>
                <div className="demo-logo" style={{ color: 'white', fontSize: '16pt' }}>
                    PPEX Customer Portal
                    <Search placeholder="Search..."
                        onSearch={onSearch}
                        style={{ width: 200, marginLeft: 18, marginTop: 18 }} />
                </div>
                <Menu
                    mode="horizontal"
                    defaultSelectedKeys={[location.pathname]}
                    selectedKeys={[location.pathname]}
                    items={menuItems.filter((item: any) => hasRole(roles, getRoleForPath(item.key)))}
                    style={{ flex: 1, minWidth: 0, marginLeft: '18px' }}
                    onSelect={({ key }) => navigate(key)}
                />
            </Header>
            <Content style={{ padding: '0 24px' }}>
                <Breadcrumb style={{ margin: '12px 0' }} items={crumbs} />
                <div
                    style={{
                        background: colorBgContainer,
                        minHeight: 280,
                        padding: "12px 24px",
                        borderRadius: borderRadiusLG,
                        border: '1px solid #CCC',
                        marginBottom: 20,
                        boxShadow: '10px 10px 10px rgba(0, 0, 0, 0.2)',
                    }}
                    className='page-content'
                >
                    <Outlet />
                </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>
                PPEX Customer Portal ©{new Date().getFullYear()} PPEX
            </Footer>
        </Layout>
    );
}

export default App;
