import { Button, Checkbox, Form, Grid, Input, theme, Typography } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title } = Typography;

export default function LoginPage() {
    const { token } = useToken();
    const screens = useBreakpoint();
    const user = useSelector((state: any) => state.user);
    const navigate = useNavigate();

    const [loginError, setLoginError] = useState(false);

    useEffect(() => {
        if (user.authenticated) {
            navigate("/");
        }
    }, [navigate, user.authenticated]);

    const onFinish = async (values: any) => {
        console.log("Received values of form: ", values);
        const result = await fetch("/api/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
        });
        const data = await result.json();
        console.log(data);
        if (data.sessionId) {
            console.log("Login successful");
            window.location.replace("/");
        } else {
            setLoginError(true);
        }
    };

    const styles = {
        container: {
            margin: "0 auto",
            padding: screens.md
                ? `${token.paddingXL}px`
                : `${token.sizeXXL}px ${token.padding}px`,
            width: "380px",
        },
        footer: {
            marginTop: token.marginLG,
            // textAlign: "center",
            width: "100%",
        },
        forgotPassword: {
            // float: "right",
        },
        header: {
            marginBottom: token.marginXL,
        },
        section: {
            alignItems: "center",
            backgroundColor: token.colorBgContainer,
            display: "flex",
            // height: screens.sm ? "100vh" : "auto",
            padding: screens.md ? `${token.sizeXXL}px 0px` : "0px",
        },
        text: {
            color: token.colorTextSecondary,
        },
        title: {
            fontSize: screens.md
                ? token.fontSizeHeading2
                : token.fontSizeHeading3,
        },
    };

    return (
        <section style={styles.section}>
            <div style={styles.container}>
                <div style={{...styles.header, textAlign: 'center'}}>
                    <Title style={styles.title}>PPEX Engage</Title>
                    {/* <Text style={styles.text}>Please log in to continue</Text> */}
                </div>
                <Form
                    name="normal_login"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    layout="vertical"
                    requiredMark="optional"
                >
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                type: "email",
                                required: true,
                                message: "Please input your Email!",
                            },
                        ]}
                    >
                        <Input prefix={<MailOutlined />} placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: "Please input your Password!",
                            },
                        ]}
                    >
                        <Input.Password
                            prefix={<LockOutlined />}
                            type="password"
                            placeholder="Password"
                        />
                    </Form.Item>
                    {loginError && (
                        <Form.Item>
                            <Text type="danger">Invalid email or password</Text>
                        </Form.Item>
                    )}
                    <Form.Item>
                        {/* <Form.Item
                            name="remember"
                            valuePropName="checked"
                            noStyle
                        >
                            <Checkbox>Remember me</Checkbox>
                        </Form.Item> */}
                        <a
                            style={styles.forgotPassword}
                            href="/forgot-password"
                        >
                            Forgot password?
                        </a>
                    </Form.Item>
                    <Form.Item style={{ marginBottom: "0px" }}>
                        <Button block={true} type="primary" htmlType="submit">
                            Log in
                        </Button>
                        {/* <div style={{ ...styles.footer, textAlign: "center" }}>
                            <Text style={styles.text}>
                                Don't have an account?
                            </Text>{" "}
                            <Link href="">Sign up now</Link>
                        </div> */}
                    </Form.Item>
                </Form>
            </div>
        </section>
    );
}
