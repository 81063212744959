import { Divider, theme } from "antd";
import IndexLink from "components/index-link";


export default function ReportsPage() {
    const {
        token: { colorPrimary },
    } = theme.useToken();

    return (
        <>
            <h1>Reports</h1>

            <Divider style={{ borderColor: colorPrimary }}>Management Reports</Divider>

            <IndexLink to="/reports/profile-overview" title="Profile Overview">
                Overview of your profile on a group level or a billing entity level
            </IndexLink>

            <IndexLink to="/reports/profile-overview" title="Exceptions Report">
                An overview of exceptions encountered by your profile which might need your attention
            </IndexLink>

            <Divider style={{ borderColor: colorPrimary }}>Detail Reports</Divider>

            <IndexLink to="/reports/wallet-transactions" title="Wallet Transactions">
                A detailed list of financial transaction against your wallet
            </IndexLink>

            <IndexLink to="/reports/profile-overview" title="Income Report">
                A detailed list of income generated by your profile over an extended period
            </IndexLink>

        </>
    );
}