import { Button } from "antd";
import { useNavigate } from "react-router-dom";


export default function NotFoundPage() {

    const navigate = useNavigate();

    return (
        <div style={{ margin: "50px" }}>
            <h1>Not Found</h1>
            <p>
                The page you requested does not exist.
                Please go <Button type="primary" onClick={() => navigate("/")}>home</Button> and try again.
            </p>
        </div>
    );
}