import { Link } from "react-router-dom";

interface IndexLinkProps extends React.PropsWithChildren<{}> {
    to: string;
    title: string;
}

export default function IndexLink({ to, title, children }: IndexLinkProps) {
    return (
        <div style={{ paddingLeft: 10 }}>
            <Link to={to}>{title}</Link>

            <p style={{ color: "gray", marginLeft: 10 }}>
                {children}
            </p>
        </div>
    );
}