import { Form, Input, InputNumber, Select } from "antd";

export default function KctOptions() {
    /*
        get input from user for 
        1. sgc
        2. krn
        3. tariff index
    */
    return (
        <>
            <Form.Item label="New SGC">
                <Select
                    defaultValue={"400161"}
                    options={[
                        { label: "400161", value: "400161" },
                        { label: "400101", value: "400101" },
                    ]}
                />
            </Form.Item>
            <Form.Item label="KRN (Key Revision Number)">
                <Select
                    defaultValue={"2"}
                    options={[
                        { label: "1", value: "1" },
                        { label: "2", value: "2" },
                    ]}
                />
            </Form.Item>
            <Form.Item label="Tariff Index">
                <Select
                    style={{ width: "100%" }}
                    options={[
                        { label: "Electricity", value: "0" },
                        { label: "Water", value: "1" },
                        { label: "Gas", value: "3" },
                    ]}
                />
            </Form.Item>
        </>
    );
}
