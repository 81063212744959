import { NavLink } from "react-router-dom";
import {
    ROLE_ACCOUNT_MANAGER,
    ROLE_ENGINEERING,
    ROLE_REPORT_VIEWER,
} from "./roles";

export const menuItems = [
    {
        key: "/",
        label: <NavLink to={"/"}>Home</NavLink>,
    },
    {
        key: "/reports",
        label: "Reports",
    },
    {
        key: "/account-management",
        label: "Account Management",
    },
    {
        key: "/settings",
        label: "Settings",
    },
    {
        key: "/logout",
        label: "Logout",
    },
];

const allowedRoles: { [key: string]: string[] } = {
    "/reports": [ROLE_REPORT_VIEWER],
    "/account-management": [ROLE_ACCOUNT_MANAGER],
    "/account-management/engineering/generate-tokens": [ROLE_ENGINEERING],
};

export function getRoleForPath(path: string) {
    if (!allowedRoles[path]) {
        return [];
    }
    return allowedRoles[path];
}

const breadCrumbs: { [key: string]: any[] } = {
    HOME: [
        {
            key: "/",
            title: <NavLink to={"/"}>Home</NavLink>,
        },
    ],
    "/reports": [
        {
            key: "/reports",
            title: "Reports",
        },
    ],
    "/reports/profile-overview": [
        {
            key: "/reports",
            title: <NavLink to={"/reports"}>Reports</NavLink>,
        },
        {
            key: "/reports/profile-overview",
            title: "Profile Overview Report",
        },
    ],
    "/account-management": [
        {
            key: "/account-management",
            title: "Account Management",
        },
    ],
    "/account-management/engineering/generate-tokens": [
        {
            key: "/account-management",
            title: (
                <NavLink to={"/account-management"}>Account Management</NavLink>
            ),
        },
        {
            key: "/account-management/engineering/generate-tokens",
            title: "Generate Engineering Tokens",
        },
    ],
    "/settings": [
        {
            key: "/settings",
            title: "Settings",
        },
    ],
};

export function getBreadcrumbs(key: string): any[] {
    if (!breadCrumbs[key]) {
        return breadCrumbs["HOME"];
    }
    return [...breadCrumbs["HOME"], ...breadCrumbs[key]];
}
