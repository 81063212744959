import { Button, Form, Select } from "antd";
import EntityAutocomplete from "components/autocomplete-meter";
import { ReactNode, useEffect, useState } from "react";
import KctOptions from "./kct-options";

export default function GenerateEngineeringTokensPage() {
    const [meter, setMeter] = useState<{
        id: string;
        label: string;
    } | null>(null);

    const [extraInfo, setExtraInfo] = useState<ReactNode | null>(null);

    const onMeterSelect = (meter: { id: string; label: string } | null) => {
        setMeter(meter);
    };

    const [tokenType, setTokenType] = useState<string | null>(null);

    useEffect(() => {
        if (tokenType === "kct") {
            setExtraInfo(<KctOptions />);
        } else {
            setExtraInfo(null);
        }
    }, [tokenType]);

    return (
        <>
            <h1>Generate Engineering Token</h1>

            <Form style={{ maxWidth: 300 }} layout="vertical">
                <h3>Step 1. Select meter</h3>

                <Form.Item label="Meter" fieldId="meterId">
                    <EntityAutocomplete
                        entityType="meter"
                        onSelect={onMeterSelect}
                    />
                </Form.Item>

                {meter && (
                    <>
                        {/* <div style={{ marginLeft: 40 }}>
                        <span
                            style={{
                                display: "inline-block",
                                width: 120,
                                fontWeight: "bold",
                            }}
                        >
                            Meter Nr.:
                        </span>
                        <span>{meter.label}</span>
                    </div> */}

                        <h4>Step 2. Select token type</h4>
                        <Form.Item label="Token Type">
                            <Select
                                allowClear
                                onClear={() => setTokenType(null)}
                                onSelect={(value) => setTokenType(value)}
                                options={[
                                    { label: "Key Change Token", value: "kct" },
                                    {
                                        label: "Clear Tamper Token",
                                        value: "tamper",
                                    },
                                ]}
                            />
                        </Form.Item>
                        {extraInfo}
                        {tokenType && (
                            <div>
                                <h4>Step 3. Generate token</h4>
                                <Button
                                    type="primary"
                                    style={{ marginTop: 10 }}
                                >
                                    Generate Token
                                </Button>
                            </div>
                        )}
                    </>
                )}
            </Form>
        </>
    );
}
