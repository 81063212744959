import { useEffect, useState } from "react";

type RoleList = string[] | undefined;
interface RoleDefinition {
    roleName: string;
    isAllowed: boolean;
}

export function hasRole(
    userRoles: RoleDefinition[],
    requiredRoles: RoleList
): boolean {
    if (!requiredRoles || requiredRoles.length === 0) {
        return true;
    }

    if (!userRoles || userRoles.length === 0) {
        return false;
    }

    const isSuperAdmin = userRoles.some(
        (role) => role.roleName === "Super Admin" && role.isAllowed
    );
    if (isSuperAdmin) {
        return true;
    }

    const disallowedRoles = userRoles
        .filter((role) => !role.isAllowed)
        .map((role) => role.roleName);

    // If any of the allowed roles are in the disallowed roles list, return false
    if (requiredRoles.some((role) => disallowedRoles.includes(role))) {
        return false;
    }

    const allowedRoles = userRoles
        .filter((role) => role.isAllowed)
        .map((role) => role.roleName);
    return requiredRoles.some((role) => allowedRoles.includes(role));
}

export function useDebounce(cb: any, delay: number) {
    const [debounceValue, setDebounceValue] = useState(cb);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebounceValue(cb);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [cb, delay]);
    return debounceValue;
}

export function debounce<T>(fn: (a: T) => void, delay: number): (a: T) => void {
    var handler: NodeJS.Timeout | undefined;
    const cb = (a: T) => {
        if (handler) clearTimeout(handler);

        handler = setTimeout(() => {
            fn(a);
            handler = undefined;
        }, delay);
    };
    return cb;
}
